import React, {useState} from "react";
import {Button, Container, CssBaseline, makeStyles, TextField, Typography} from "@material-ui/core";

interface authData {
    email: string;
    password: string;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = (props: any) => {
    const [auth, setAuth] = useState<authData>({email: "", password: ""});
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    DIGI MANAGEMENT
                </Typography>
                <form className={classes.form} noValidate
                      onSubmit={(e) => {
                          e.preventDefault()

                          fetch(`https://secure.staxus.com/api/auth/login`, {
                              method: "POST",
                              headers: {
                                  "Content-Type": "application/json"
                              },
                              body: JSON.stringify(auth)
                          })
                              .then(response => response.json())
                              .then(data => {
                                  localStorage.setItem("STAXUS_ACCESS_TOKEN", data.token)
                                  props.history.push("/")
                              })
                              .catch(error => setAuth({email: "", password: ""}))

                      }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setAuth({...auth, email: e.target.value})}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setAuth({...auth, password: e.target.value})}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>

                </form>
            </div>
        </Container>

    )
}

export default Login;