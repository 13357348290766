import React, {useEffect, useState} from "react";
import { Route, RouteComponentProps, Redirect } from "react-router-dom";

interface Props {
    Component: React.FC<RouteComponentProps>;
    path: string;
    exact?: boolean;
}

const PrivateRoute = ({Component, path, exact=false}:Props) => {
    //const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true)
    //const [authToken, setAuthToken] = useState('')
    const isAuthenticated = !!localStorage.getItem("STAXUS_ACCESS_TOKEN")
    const message = 'Please log in to view this page'

    // const checkToken = () => {
    //     fetch(
    //         'https://secure.staxus.com/api/auth/isAuthenticated',
    //         {headers: {Authorization: 'Bearer ' + authToken}}
    //     )
    //         .then(response => response.json())
    //         .then(response => {
    //             console.log(response)
    //             //setIsAuthenticated(true)
    //         })
    //         .catch((error) => setIsAuthenticated(false));
    // }
    //
    // async function fetchAuthToken() {
    //     const token = await localStorage.getItem("STAXUS_ACCESS_TOKEN")
    //     if(token) {
    //         setAuthToken(token)
    //     }
    // }
    //
    // useEffect(() => {
    //         fetchAuthToken()
    //     }, []
    // );
    //
    // useEffect(() => {
    //     if(authToken) {
    //         checkToken()
    //     }
    // }, [authToken])


    return (
        <Route
            exact={exact}
            path={path}
            render={(props: RouteComponentProps) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                message,
                                requestedPath: path
                            }
                        }}
                    />
                )
            }
        />
    )
}

export default PrivateRoute;
