import React, {useEffect, useState} from "react";
import {ColDef, DataGrid} from '@material-ui/data-grid';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";

export interface MailCustomer {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
}


export default function Customers() {
    const [data, setData] = useState<MailCustomer[]>([])
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<number>(25)


    const [loading, setLoading] = useState<boolean>(false)
    const [authToken, setAuthToken] = useState('')


    const fetchData = () => {
        setLoading(true);
        fetch(
            'https://secure.staxus.com/api/mailcatalog/customers',
            {headers: {Authorization: 'Bearer ' + authToken}}
            )
            .then(response => response.json())
            .then(response => {
                setData(response)
                setLoading(false)
            })
            .catch((error) => setData([]));
    }

    async function fetchAuthToken() {
        const token = await localStorage.getItem("STAXUS_ACCESS_TOKEN")
        if(token) {
            setAuthToken(token)
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        window.scroll(0, 0)
        fetchData()
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        window.scroll(0, 0)
        fetchData()
    };


    useEffect(() => {
            fetchAuthToken()
        }, []
    );

    useEffect(() => {
        if(authToken) {
            fetchData()
        }
    }, [authToken])


    return(
        <div>
            <h2>Mailorder Customers</h2>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>First name</TableCell>
                            <TableCell>Last name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Address</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((customer) => (
                            <TableRow>
                                <TableCell>{customer.firstName}</TableCell>
                                <TableCell>{customer.lastName}</TableCell>
                                <TableCell>{customer.email}</TableCell>
                                <TableCell>{customer.address}, {customer.postalCode} {customer.city}, {customer.country}</TableCell>
                            </TableRow>
                            ),
                        )}
                    </TableBody>
                </Table>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </TableContainer>

        </div>


    )
}
