import React from "react";
import {Switch, Route, useRouteMatch} from "react-router-dom";
import Customers from "./Customers";

export default function Mailorder() {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/`} component={Customers} />
        </Switch>
    )
}