import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './index.css';
import Login from "./components/Auth/login";
import PrivateRoute from "./components/Auth";
import AppRoutes from "./components/Routes/AppRoutes";
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Login} />
              <PrivateRoute path="/" Component={AppRoutes} />
          </Switch>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
